export const PUBLIC_OFFER_TABLE_COLUMNS = [
  // Nombre de la campaña
  { field: 'nombre', align: 'left', width: 450 },
  // Código de la campaña
  { field: 'codigo', align: 'left', width: 130 },
  // Comunidad Autónoma
  { field: 'ccaa', align: 'left', width: 130 },
  // Mercados
  { field: 'mercados', align: 'left', width: 80 },
  // Productos
  { field: 'productos', align: 'left', width: 140 },
  // Retribución Económica
  { field: 'descuento', align: 'center', width: 130 },
  // Ingreso a cuenta
  // { field: 'ingreso', align: 'right', width: 100 },
  // Ingreso a cuenta
  // {
  //   field: 'financiacion',
  //   align: 'center',
  //   width: 100,
  //   render: (rowData) => (rowData.financiacion ? `${rowData.meses_financiacion} meses` : 'No'),
  // },
  //origen de la Oferta Pública
  { field: 'origen', align: 'left', width: 150 },
  // Prioridad de la campaña
  // { field: 'prioridad', align: 'left', width: 150 },
  // Activa
  { field: 'state', disableClick: true, align: 'center', width: 120 },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
