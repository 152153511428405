import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ThemeButton } from '../../../../ui'
import { INPUTS_CONFIG, FORM_CONFIG } from './public-offer-form.constants'
import { GridForm } from './grid-form.component'
import { usePublicOfferContext } from '../../contexts'
import { useCombos } from '../../../combos/hooks'
import { usePublicOfferValidations } from '../../hooks'
import { CloneBtn } from '../buttons'
import { preparePublicOfferDto } from './public-offer-form.utils'

export const PublicOfferEditForm = () => {
  const permissions = useSelector((state) => state.global.permissions)
  const intl = useIntl()
  const combos = useCombos(['gremios'])
  const { publicOffer, editPublicOffer } = usePublicOfferContext()
  const { formErrors } = usePublicOfferValidations()

  const [formState, setFormState] = useState({
    ...publicOffer,
    financiacion: publicOffer.financiacion ? '01' : '00',
    is_op: true,
  })
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (e) => {
    if (
      e.target.value === 'cliente' ||
      e.target.value === 'instalador' ||
      e.target.value === 'gremio'
    ) {
      eraseGremiosAndProvsFields(e)
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    }
  }

  const eraseGremiosAndProvsFields = (e) => {
    delete formState.comunidades
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleCancel = () => {
    setErrorMssg(false)
    setFormState({ ...publicOffer, financiacion: publicOffer.financiacion ? '01' : '00' })
    setEditMode(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const errors = formErrors(formState)
    if (editMode) {
      if (!errors) {
        setLoading(true)
        editPublicOffer(preparePublicOfferDto(formState))
          .then((data) => {
            setErrorMssg(errors)
            setEditMode(false)
            setFormState({ ...data, financiacion: data.financiacion ? '01' : '00' })
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        setErrorMssg(errors)
      }
    } else {
      setEditMode(true)
    }
  }

  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
    readOnly: !editMode,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" mt={2} mb={2}>
        {permissions['editar_op'] ? (
          <Box display="flex" justifyContent="flex-start" flex={1}>
            <CloneBtn {...{ disabled: editMode }} />
          </Box>
        ) : null}
        <Box display="flex" justifyContent="flex-end">
          {editMode ? (
            <Box mr={1}>
              <ThemeButton color="default" onClick={handleCancel} disabled={loading}>
                {intl.formatMessage({ id: 'global.cancel' })}
              </ThemeButton>
            </Box>
          ) : null}
          {permissions['editar_op'] ? (
            <ThemeButton type="submit" color="primary" loading={loading}>
              {intl.formatMessage({
                id: `pages.campaigns.detail.button.${editMode ? 'save' : 'edit'}.label`,
              })}
            </ThemeButton>
          ) : null}
        </Box>
      </Box>
      {errorMssg ? <Alert severity="error">{errorMssg}</Alert> : null}
      <GridForm {...{ grid: FORM_CONFIG, inputs: INPUTS }} />
    </form>
  )
}
