export const CAMPAIGNS_TABLE_COLUMNS = [
  // Nombre de la campaña
  { field: 'nombre', align: 'left', width: 120 },
  // Código de la campaña
  { field: 'codigo', align: 'left', width: 100 },
  // Comunidad Autónoma
  { field: 'ccaa', align: 'left', width: 130 },
  // Mercados
  { field: 'mercados', align: 'left', width: 80 },
  // Productos
  { field: 'productos', align: 'left', width: 140 },
  // Subvención
  { field: 'descuento', align: 'right', width: 100 },
  // Ingreso a cuenta
  { field: 'ingreso', align: 'right', width: 100 },
  // Ingreso a cuenta
  {
    field: 'financiacion',
    align: 'left',
    width: 100,
    render: (rowData) => (rowData.financiacion ? `${rowData.meses_financiacion} meses` : 'No'),
  },
  // Prioridad de la campaña
  // { field: 'prioridad', align: 'left', width: 150 },
  // Activa
  { field: 'state', disableClick: true, align: 'center', width: 120 },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
