import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'

const CloneCampaignForm = ({ campaign, createCampaign, history, close }) => {
  const { formatMessage } = useIntl()
  const todayDate = new Date()
  const yesterdayDate = todayDate.setDate(todayDate.getDate() - 1)
  const newCampaign = {
    ...campaign,
    nombre: campaign.nombre + ' bis',
    fechainicio: new Date(yesterdayDate).toISOString(),
    fechafin: new Date().toISOString(),
  }

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState(newCampaign)

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    setLoading(true)
    const { id, ...restFormState } = formState
    const payload = restFormState
    createCampaign(payload)
      .then((data) => {
        setLoading(false)
        history.push(`/campaigns/${data.id}`)
        close()
      })
      .catch(() => setLoading(false))
  }

  return (
    <form onSubmit={handleConfirm}>
      <Box mb={4}>
        <TextInput
          {...{
            label: 'Nombre',
            name: 'nombre',
            value: formState['nombre'],
            required: true,
            onChange: handleChange,
          }}
        />
      </Box>
      <Box mb={4}>
        <TextInput
          {...{
            label: 'Codigo',
            name: 'codigo',
            value: formState['codigo'],
            required: true,
            onChange: handleChange,
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ThemeButton onClick={close} variant="outlined" labelM="lg" disabled={loading}>
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton type="submit" color="primary" labelM="lg" disabled={loading} loading={loading}>
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </form>
  )
}

export const CloneCampaignDialog = (props) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <Box mb={4} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.campaigns.dialogs.clone.title' })}
        </Typography>
      </Box>
      <CloneCampaignForm {...props} />
    </Box>
  )
}
