import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { FilterBox } from '../../../../ui'
import { CAMPAIGNS_FILTERS } from './campaigns-filter.constants'
import { AddCampaignBtn } from '../buttons'

export const CampaignsFilter = () => {
  const { formatMessage } = useIntl()
  const combos = useSelector((state) => state.combos)
  const filters = CAMPAIGNS_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.campaigns.filters.' + item.name + '.title',
      }),
    }
  })
  return (
    <FilterBox filters={filters}>
      <Box display="flex" justifyContent="flex-end">
        <AddCampaignBtn />
      </Box>
    </FilterBox>
  )
}
